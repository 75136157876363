import github from '../../assets/github.png';
import discord from '../../assets/discord.png';
import mail from '../../assets/mail.png';
import twitter from '../../assets/twitter.png';

import Option from './Option';

import { MailIcon } from '@heroicons/react/solid'

export default function Sidebar() {
    return (
        <div className="bg-slate-800 mt-5 rounded-md p-2 shadow-lg w-full flex justify-center md:flex-col md:justify-start flex-wrap">
            <Option href='https://GitHub.com/Angoooose/'>
                <img src={github} className="mr-2 h-7"/>
                Angoooose
            </Option>
            <Option href=''>
                <img src={discord} className="mr-2 h-8"/>
                Angoose<span className="text-gray-500">#3794</span>
            </Option>
            <Option href="mailto:angoose.dev@gmail.com">
                <MailIcon className="mr-2 h-7"/>
                contact@angoose.dev
            </Option>
            <Option href="https://twitter.com/RealAngoose">
                <img src={twitter} className="mr-2 h-6"/>
                <span className="text-gray-500 group-hover:text-blue-400 transition-all">@</span> RealAngoose
            </Option>
        </div>
    );
}