import { FC } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import { Tag } from './components/common/Tag';
import Projects from './components/Projects/Projects';

export const App: FC = () => {
    return (
        <div className="w-full min-h-screen bg-slate-900 text-white pt-14 py-2 px-5 sm:px-10 md:px-20 flex flex-col md:flex-row">
            <div className="md:max-w-xs flex flex-col items-center">
                <h1 className="text-5xl font-bold text-center whitespace-nowrap w-full">
                    <span className="text-gray-500 font-normal">{'< '}</span>
                    Angoose
                    <span className="text-gray-500 font-normal">{' />'}</span>
                </h1>
                <Sidebar/>
                <div className="bg-slate-800 mt-5 rounded-md p-2 shadow-lg w-full">
                    <h2 className="text-lg font-semibold text-center">My Favorites</h2>
                    <div className="flex justify-center items-center flex-wrap mt-0.5">
                        <Tag>TypeScript</Tag>
                        <Tag>React</Tag>
                        <Tag>NextJS</Tag>
                        <Tag>TailwindCSS</Tag>
                        <Tag>MongoDB</Tag>
                        <Tag>PostgreSQL</Tag>
                        <Tag>Docker</Tag>
                        <Tag>NestJS</Tag>
                    </div>
                </div>
            </div>
            <Projects/>
        </div>
    );
}

export default App;