import { FC, ReactNode } from 'react';

interface TagProps {
    children: ReactNode
}

export const Tag: FC<TagProps> = ({ children }) => {
    return (
        <span className="px-2 py-0.5 m-1 border border-blue-600 text-blue-400 rounded-full text-sm first:ml-0 last:mr-0">
            {children}
        </span>
    );
}