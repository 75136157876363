import ProjectCard from './ProjectCard';

export default function Projects() {
    return (
        <div className="mt-5 md:ml-10 md:mt-0">
            <h1 className="text-3xl font-bold">Projects</h1>
            <hr className="w-full border-slate-600 mt-1"/>
            <div className="flex flex-wrap mt-5">
                <ProjectCard
                    title='EZ Poll'
                    description='A polling web application built to be as simple and easy-to-use as possible.'
                    tags={['nextjs', 'tailwind', 'postgres', 'rabbitmq']}
                    githubLink='https://github.com/Angoooose/ez-poll'
                    demoLink='https://polls.angoose.dev/'
                />
                <ProjectCard
                    title='Hermes'
                    description='A truly temporary web-based messaging app. Accounts only last 48 hours.'
                    tags={['react', 'firebase']}
                    githubLink='https://github.com/Angoooose/hermes'
                    demoLink='https://hermes.angoose.dev/'
                />
                <ProjectCard
                    title='Rolodex'
                    description='A contact management web application. Inspired from the roldexes of old.'
                    tags={['react', 'firebase', 'tailwind']}
                    githubLink='https://github.com/Angoooose/rolodex'
                    demoLink='https://rolodex.angoose.dev/'
                />
                <ProjectCard
                    title='Historian Bot'
                    description='A Discord bot that displays historical events either on command or automatically.'
                    tags={['discord.js']}
                    githubLink='https://github.com/HistoryLabs/historian-bot'
                    demoLink='https://discord.com/oauth2/authorize?client_id=743224756408549457&permissions=3072&scope=bot%20applications.commands'
                />
                <ProjectCard
                    title='Tic Tac Toe'
                    description='A tic tac toe game against a CPU with three levels of difficulty: easy, normal, and hard.'
                    tags={['react', 'firebase']}
                    githubLink='https://github.com/Angoooose/tic-tac-toe-v2'
                />
            </div>
        </div>
    );
}