import { FC, ReactNode } from 'react';

interface ButtonProps {
    children: ReactNode,
    color: 'primary'|'secondary',
    href: string,
}

const ProjectButton: FC<ButtonProps> = ({ children, color, href }) => {
    return (
        <a href={href} className={`border transition-all ${color === 'primary' ? 'border-green-600 text-green-400 hover:bg-green-600 hover:text-white' : 'border-slate-600 text-slate-400 hover:bg-slate-600 hover:text-white'} px-2 py-1 rounded-md flex-grow mx-1 flex justify-center items-center`}>
            {children}
        </a>
    );
}

export default ProjectButton;