import { FC } from 'react';
import ProjectButton from './ProjectButton';

interface ProjectCardProps {
    title: string,
    description: string,
    tags: string[],
    githubLink: string,
    demoLink?: string,
}

const ProjectCard: FC<ProjectCardProps> = ({ title, description, tags, githubLink, demoLink }) => {
    return (
        <div className="bg-slate-800 border border-slate-700 max-w-sm rounded-md px-4 py-2 m-2 shadow-lg">
            <h2 className="text-lg font-semibold">{title}</h2>
            <p className="mt-1 text-gray-400">{description}</p>
            <div className="flex items-center flex-wrap mt-1">
                {tags?.map(tag => (
                    <span className="px-2 py-0.5 m-1 border border-blue-600 text-blue-400 rounded-full text-sm first:ml-0 last:mr-0">
                        {tag}
                    </span>
                ))}
            </div>
            <div className="flex items-center mt-2">
                <ProjectButton color="primary" href={githubLink}>GitHub</ProjectButton>
                {demoLink && (
                    <ProjectButton color="secondary" href={demoLink}>
                        Demo
                    </ProjectButton>
                )}
            </div>
        </div>
    );
}

export default ProjectCard;