import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode,
    href: string,
}

const Option: FC<Props> = ({ children, href }) => {
    return (
        <a href={href} className="text-lg rounded-md p-2 mx-4 md:mx-0 flex items-center hover:bg-blue-600 hover:shadow-lg transition-all group justify-center">
            {children}
        </a>
    );
}

export default Option;